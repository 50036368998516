import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import backgroundImage from "./assets/images/background.jpg";
import { Layout } from "./Components/layout";
import { Home } from "./Containers/Home";
import { Navigator } from "./Containers/Navigator";
import { StyledHeader } from "./Components/header";

const navigation = {
  es: [
    {
      title: "CONTACTO",
      to: "/es/contacto"
    },
    {
      title: "INFO",
      to: "/es/info"
    },
    {
      title: "PROYECTOS",
      to: "https://www.facebook.com/karim.lahbabi.elalami/"
    }
  ],
  fr: [
    {
      title: "CONTACT",
      to: "/fr/contact"
    },
    {
      title: "INFO",
      to: "/fr/info"
    },
    {
      title: "PROJETS",
      to: "https://www.facebook.com/karim.lahbabi.elalami/"
    }
  ]
};

const App = () => {
  const [language, setLanguage] = useState(null);

  React.useEffect(() => {}, [language]);

  return (
    <Layout
      style={{
        backgroundImage: `url('${backgroundImage}')`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    >
      <StyledHeader
        navigationItems={navigation[language]}
        dark={Boolean(language)}
        siteTitle={"KARIM LAHBABI"}
      />
      <Switch>
        <Route path="/" exact>
          <Home setLanguage={setLanguage} />
        </Route>
        <Route path="/:language">
          <Navigator setLanguage={setLanguage} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;
