import React from "react";
import { StyledLink } from "../Components/link";
import { StyledText } from "../Components/text";

export const Home = ({ setLanguage }) => {
  setLanguage(null);
  return (
    <div style={{ position: "absolute", top: 25, left: 25, display: "flex" }}>
      <StyledLink href="/es" style={{ color: "#fff" }}>
        {"ES"}
      </StyledLink>
      <StyledText style={{ color: "#fff" }}>{"|"}</StyledText>
      <StyledLink href="/fr" style={{ color: "#fff" }}>
        {"FR"}
      </StyledLink>
    </div>
  );
};
