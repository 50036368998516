import React from "react";
import styled from "styled-components";
import profile from "../assets/images/profile.jpg";
import { StyledText } from "../Components/text";

const info = {
  es:
    "Viviendo en la Costa del Sol durante más de 20 años, tengo experiencia en alquiler, venta y restauración de multitud de propiedades. No lo dudes y cuenta conmigo para tu proyecto.",
  fr:
    "Je vis sur la Costa del Sol depuis plus de 20 ans, j’ai de l’expérience en location, vente et restauration de nombreuses propriétés. N’hésitez pas et comptez sur moi pour votre projet."
};

const StyledImage = styled.img`
  @media only screen and (min-width: 1200px) {
    height: 175px;
    margin-left: 45px;
  }
`;

const InfoText = ({ className, language }) => {
  return (
    <StyledText className={className} size={20}>
      {info[language]}
    </StyledText>
  );
};

const StyledInfoText = styled(InfoText)`
  color: #fff;
  letter-spacing: 0;
  width: 90%;
  line-height: 1.8;
`;

const InfoComponent = ({ className, language }) => {
  return (
    <div className={className}>
      <StyledInfoText language={language} />

      <StyledImage
        alt=""
        src={profile}
        style={{
          height: "150px",

          border: "1px solid white",
          marginTop: "15px"
        }}
      />
    </div>
  );
};

export const Info = styled(InfoComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  width: 90%;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 1200px) {
    position: absolute;
    width: 60%;
    left: 25%;
    bottom: 14.5%;
  }
`;
