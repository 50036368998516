import React, { useState } from "react";

export const Layout = ({ children, style }) => {
  const [defaultStyle, setDefaultStyle] = useState({
    height: window.innerHeight,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  });
  React.useLayoutEffect(() => {
    function handleResize() {
      setDefaultStyle(() => ({
        ...defaultStyle,
        height: window.innerHeight
      }));
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return <div style={{ ...defaultStyle, ...style }}>{children}</div>;
};
