import React from "react";
import styled from "styled-components";
import { StyledText } from "../Components/text";

const contact = {
  es: "TELÉFONO",
  fr: "TÉLÉPHONE"
};

const ContactComponent = ({ className, language }) => {
  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: "30px"
        }}
      >
        <StyledText
          style={{
            color: "#fff",
            letterSpacing: "10px",
            lineHeight: 1.8
          }}
          size={25}
        >
          {contact[language]}
        </StyledText>
        <a style={{ textDecoration: "none" }} href="tel:+34609890099">
          <StyledText
            style={{
              color: "#fff",
              letterSpacing: 0,
              lineHeight: 1.8
            }}
            size={20}
          >
            {"+34 609 89 00 99"}
          </StyledText>
        </a>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <StyledText
          style={{
            color: "#fff",
            letterSpacing: "10px",
            lineHeight: 1.8
          }}
          size={25}
        >
          {"E-MAIL"}
        </StyledText>
        <a
          style={{ textDecoration: "none" }}
          href="mailto:karim.lahbabi@gmail.com"
        >
          <StyledText
            style={{
              color: "#fff",
              letterSpacing: 0,
              lineHeight: 1.8
            }}
            size={20}
          >
            {"karim.lahbabi@gmail.com"}
          </StyledText>
        </a>
      </div>
    </div>
  );
};

export const Contact = styled(ContactComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  max-width: 850px;

  width: 90%;

  @media only screen and (min-width: 1200px) {
    width: 40%;
    position: absolute;
    left: 5%;
    bottom: 13.8%;
  }
`;
