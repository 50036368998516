import React from "react";
import styled, { css } from "styled-components";
import { StyledText } from "./text";
import { StyledNavigation } from "./navigation";

const SiteTitle = ({ siteTitle, dark, className }) => (
  <StyledText hidd className={className} dark={dark}>
    {siteTitle}
  </StyledText>
);

const StyledSiteTitle = styled(SiteTitle)`
  font-size: 20px;
  display: none;
  text-align: center;
  ${props => css`
    display: ${props.dark ? "none" : "flex"};
  `}

  @media only screen and (min-width: 1080px) {
    display: flex;
    font-size: 25px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 40px;
    margin-left: 30px;
  }
`;

const Header = ({ siteTitle, navigationItems, dark, className }) => {
  return (
    <header className={className}>
      {navigationItems && (
        <StyledNavigation dark={dark} navigationItems={navigationItems} />
      )}
      <StyledSiteTitle dark={dark} siteTitle={siteTitle} />
    </header>
  );
};

export const StyledHeader = styled(Header)`
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 250ms linear;
  box-sizing: border-box;

  @media only screen and (min-width: 786px) {
    justify-content: space-around;
    padding: 0% 3% 0% 5%;
  }

  ${props => css`
    background-color: ${props.dark
      ? "rgba(0, 0, 0, 0.7);"
      : "rgba(255, 255, 255, 0.7);"};
  `}
`;
