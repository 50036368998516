import React from "react";
import styled, { css } from "styled-components";

const Text = ({ children, className, style }) => (
  <p style={style} className={className}>
    {children}
  </p>
);

export const StyledText = styled(Text)`
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  letter-spacing: 15px;
  font-style: normal;
  font-weight: normal;
  margin-right: 0;
  transition: color 250ms linear;
  text-align: center;
  text-decoration: none;

  ${props => css`
    font-size: ${props.size * 0.75}px;
    color: ${props.dark ? "#fff" : "#000"};

    @media only screen and (min-width: 768px) {
      text-align: left;
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${props.size}px;
    }
  `}
`;
