import React from "react";
import styled from "styled-components";
import { StyledLink } from "./link";
import { useRouteMatch } from "react-router-dom";

const NavigationItem = ({ className, href, dark, children, component }) => {
  const match = useRouteMatch(href);
  return (
    <li className={className}>
      <StyledLink size={20} dark={dark} href={href}>
        {children}
      </StyledLink>
      {match && match.isExact && component}
    </li>
  );
};

const StyledNavigationItem = styled(NavigationItem)`
  margin: 5px;
  padding: 0;
  position: relative;

  @media only screen and (min-width: 1080px) {
    margin: 10px;
  }
`;

export const Navigation = ({ className, dark, navigationItems }) => (
  <ul className={className}>
    {navigationItems &&
      navigationItems.map((navigationItem, index) => {
        return (
          <StyledNavigationItem
            key={index}
            dark={dark}
            href={navigationItem.to}
            component={navigationItem.component}
          >
            {navigationItem.title}
          </StyledNavigationItem>
        );
      })}
  </ul>
);

export const StyledNavigation = styled(Navigation)`
  list-style: none;
  display: flex;
  flex-grow: 2;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  max-width: 920px;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;
