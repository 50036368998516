import React from "react";
import { Link } from "react-router-dom";
import { StyledText } from "./text";
import styled from "styled-components";

const AppLink = ({ className, href, dark, size, children, style }) => {
  if (href && href.includes("http")) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        <StyledText dark={dark} style={style} size={size}>
          {children}
        </StyledText>
      </a>
    );
  }

  return (
    <Link to={href} className={className}>
      <StyledText dark={dark} style={style} size={size}>
        {children}
      </StyledText>
    </Link>
  );
};

export const StyledLink = styled(AppLink)`
  text-decoration: none;
  color: #000;
`;
