import React from "react";
import { useParams, Switch, Route, useRouteMatch } from "react-router-dom";
import { Info } from "./Info";
import { Contact } from "./Contact";
import styled from "styled-components";

const StyledContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1000ms linear;
  align-self: flex-end;
  margin-top: 45px;

  @media only screen and (min-width: 768px) {
    width: 80%;
    margin-top: 0;
  }

  @media only screen and (min-width: 1200px) {
    width: 60%;
  }
`;

export const Navigator = ({ setLanguage }) => {
  const { language } = useParams();
  let match = useRouteMatch();

  setLanguage(language);
  return (
    <Switch>
      <Route path={`${match.path}/info`}>
        <StyledContentWrapper>
          <Info language={language} />
        </StyledContentWrapper>
      </Route>
      <Route path={`${match.path}/contact`}>
        <StyledContentWrapper>
          <Contact language={language} />
        </StyledContentWrapper>
      </Route>
      <Route path={`${match.path}/contacto`}>
        <StyledContentWrapper>
          <Contact language={language} />
        </StyledContentWrapper>
      </Route>
    </Switch>
  );
};
